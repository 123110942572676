import { createGlobalStyle } from 'styled-components';

import { colors } from 'src/theme/colors';

export default createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  body {
    background-color: ${colors.white};
    color: ${colors.black};
    font-family: 'Gordita', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.6;
    overflow-x: hidden;
    position: relative;
    scroll-behavior: smooth;
    white-space: pre-line;
  }

  a {
    text-decoration: none;
  }

  .overflow-hidden {
    overflow: hidden;
  }
`;
