import { createGlobalStyle } from 'styled-components';

import GorditaWoff from 'src/static/fonts/gordita-regular.woff';
import GorditaWoff2 from 'src/static/fonts/gordita-regular.woff2';
import GorditaWoffMedium from 'src/static/fonts/gordita-medium.woff';
import GorditaWoff2Medium from 'src/static/fonts/gordita-medium.woff2';
import GorditaWoffBold from 'src/static/fonts/gordita-bold.woff';
import GorditaWoff2Bold from 'src/static/fonts/gordita-bold.woff2';

const FontStyles = createGlobalStyle`
    @font-face {
        font-family: "Gordita";
        font-style: normal;
        font-weight: 400;
        src: url(${GorditaWoff2}) format("woff2"),
        url(${GorditaWoff}) format("woff");
    }

    @font-face {
        font-family: "Gordita";
        font-style: normal;
        font-weight: 500;
        src: url(${GorditaWoff2Medium}) format("woff2"),
            url(${GorditaWoffMedium}) format("woff");
    }

    @font-face {
        font-family: "Gordita";
        font-style: normal;
        font-weight: 700;
        src: url(${GorditaWoff2Bold}) format("woff2"),
            url(${GorditaWoffBold}) format("woff");
    }
`;

export default FontStyles;
