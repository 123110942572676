export const colors = {
  white: '#FFFFFF',
  black: '#393944',
  green100: '#C3EC64',
  green300: '#80CBC8',
  green400: '#43A5A1',
  grey100: '#F1F5F8',
  grey200: '#DADFE2',
  grey600: '#8A8C91',
  purple100: '#F3F3FF',
  purple600: '#7C7CE8',
  purple700: '#635BFF',
  yellow: '#FDCB6C',
};
