import React from 'react';
/* eslint-disable import/no-unresolved */
import { register } from 'swiper/element/bundle';

import GlobalStyles from './src/static/styles/global.css';
import FontStyles from './src/theme/fonts';
import { ModalProvider } from './src/components/Modal/ModalContext';

export const onClientEntry = () => {
  register(); // Register swiper.js
};

export const wrapRootElement = ({ element }) => {
  return <ModalProvider>{element}</ModalProvider>;
};

export const wrapPageElement = ({ element }) => (
  <>
    <GlobalStyles />
    <FontStyles />
    {element}
  </>
);
